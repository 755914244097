import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Paper, Tab, Tabs } from '@mui/material';
import Layout from '../../components/layout';
import TabPanel from '../../components/tab-panel';
import TeamImageList from '../../components/team-image-list';
import TeamMemberList from '../../components/team-member-list';
import '../../styles/team.scss';
import Widget from '../../components/widget';

interface ITeamMember {
  firstName: string;
  lastName: string;
  position: string;
  image: string;
};

interface INode {
  frontmatter: ITeamMember
};

type TeamPageProps = {
  data: {
    allMarkdownRemark: {
      nodes: Array<INode>
    }
  },
  pageContext: {
    teamId: number;
  }
};

export default function TeamPage({ data, pageContext }: TeamPageProps) {
  const { t } = useI18next();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let matchesWidgetId : string = '';
  let tableWidgetId: string = '';
  switch(pageContext.teamId) {
    case 1:
      tableWidgetId = '535158e8-a0e9-4588-88f9-ff8eff43246c';
      matchesWidgetId = '535dcd7b-58cb-41e4-b5a9-50abf81d7193';
      break;
    case 2:
      tableWidgetId = 'a8728ce4-4654-4dcd-a6b7-663f77984d36';
      matchesWidgetId = 'b1961176-7266-4306-97c5-7b2cee96ff58';
      break;
    case 3:
      tableWidgetId = '9e5b0d8d-daa5-4163-afb1-c73611cf76e8';
      matchesWidgetId = 'd6acec81-ce3e-4450-b358-9ed40269f27f';
      break;
  }

  return (
    <Layout>
      <Paper className="tabs">
        <Tabs value={value} onChange={handleChange} textColor="inherit">
          <Tab className="tab" label={t("players")}/>
          <Tab className="tab" label={t("matches")}/>
          <Tab className="tab" label={t("table")}/>
          <Tab className="tab" label={t("galery")}/>
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <TeamMemberList teamId={pageContext.teamId} teamMemberData={data.allMarkdownRemark.nodes}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="utils-margin-bottom-20"></div>
        <Widget id={matchesWidgetId} type="team-matches"/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="utils-margin-bottom-20"></div>
        <Widget id={tableWidgetId} type="table"/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="utils-margin-bottom-20"></div>
        <TeamImageList teamId={pageContext.teamId}/>
      </TabPanel>
    </Layout>
  );
}

export const pageQuery = graphql`
  query getTeamMembersById($teamId: Int!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allMarkdownRemark(
      filter: { frontmatter: { teamId: { eq: $teamId } } },
      sort: { fields: [frontmatter___firstName], order: ASC }
    ) {
      nodes {
        frontmatter {
          firstName,
          lastName,
          position,
          image
        }
      }
    }
  }
`;
