import React from 'react';
import { Box } from '@mui/material';

type TeamImageListProps = {
  teamId: number;
};

export default function TeamImageList({ teamId }: TeamImageListProps) {
  let years : number[] = [];
  // TODO: load images dynamically
  switch(teamId) {
    case 1:
      years = [
        1971,
        1980,
        1981,
        1982,
        1983,
        1985,
        1986,
        1987,
        1988,
        1990,
        1991,
        1993,
        1994,
        1995,
        1998,
        1999,
        2001,
        2002,
        2003,
        2004,
        2005,
        2006,
        2009,
        2010,
        2011,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2020
      ];
      break;
    case 2:
      years = [
        1989,
        1990,
        1992,
        1994,
        1999,
        2000,
        2001,
        2002,
        2004,
        2005,
        2006,
        2008,
        2016,
        2020
      ];
      break;
    case 3:
      years = [
        1980,
        2013,
        2019
      ];
      break;
  }
  let teamImageList = [];
  for (let year of years) {
    teamImageList.push(
      <div key={year}>
        <div>
          <Box fontWeight="fontWeightBold">{year}/{year + 1}</Box>
        </div>
        <div className="utils-margin-bottom-10"></div>
        <div className="utils-margin-bottom-20">
          <img src={`/img/teams/${teamId}/old/${year}.jpg`} className="responsive-image"/>
        </div>
      </div>
    );
  }

  return (
    <div>
      {teamImageList}
    </div>
  );
}
